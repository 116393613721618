/**
 * Remote Toggles Configuration - Feature Toggling, Gradual Rollouts, and Permanent Toggles
 *
 * Use this object to define all feature toggles used in the application.
 * Each toggle must follow the `RemoteToggleType` structure.
 *
 * Example:
 * ```
 * ONTARIO_HEADER: {
 *   key: 'toggle_ontario_header',
 *   trackingKey: 'kjca420',
 *   defaultValue: {
 *     int: true,
 *     prod: false,
 *   },
 * }
 * ```
 * **Key Guidelines:**
 * - `key`: Must start with the prefix `toggle_` followed by a descriptive name for the feature flag.
 * - `trackingKey`: A key prefixed by `kjca` used for tracking. Should align with story JIRA ticket. Optional for Toggles.
 * - `defaultValue`: An object defining the default/fallback values for both integration (`int`) and production (`prod`) environments.
 */
import { type RemoteToggleType } from '@/lib/firebase/types'

export const RemoteToggles = {
  VIP_FINANCING_BANNER: {
    key: 'toggle_vip_financing',
    defaultValue: { int: true, prod: false },
  },
  AUTOS_PRICE_INSIGHT: {
    key: 'toggle_autos_price_rating',
    defaultValue: { int: true, prod: false },
  },
  AUTOS_LANDING_PAGE_LINK: {
    key: 'toggle_autos_landing_page_link',
    defaultValue: { int: false, prod: false },
  },
  SMALLER_MASTHEAD_TOGGLE: {
    key: 'toggle_smaller_non_sticky_masthead',
    defaultValue: { int: false, prod: false },
  },
  BEST_MATCH_ALGORITHM_TEST_TOGGLE: {
    key: 'toggle_exp_best_match_algorithm',
    defaultValue: { int: false, prod: false },
  },
  DEDUPLICATION_TEST_TOGGLE: {
    key: 'toggle_exp_deduplication',
    defaultValue: { int: false, prod: false },
  },
  SELLER_RESPONSIVENESS_TEST_TOGGLE: {
    key: 'toggle_exp_seller_responsiveness',
    defaultValue: { int: false, prod: false },
  },
  IMAGELESS_DOWN_BOOSTING_TEST_TOGGLE: {
    key: 'toggle_exp_imageless_down_boosting',
    defaultValue: { int: false, prod: false },
  },
  BLOCK_BANNED_USERS: {
    key: 'toggle_block_banned_users',
    defaultValue: { int: true, prod: true },
  },
  CHAMELEON_TRACKING: {
    key: 'toggle_chameleon_tracking',
    defaultValue: { int: true, prod: true },
  },
  MAP_SRP: {
    key: 'toggle_map_srp',
    defaultValue: { int: false, prod: false },
    trackingKey: 'kjca2003',
  },
  NAVIGATION_SRP: {
    key: 'toggle_navigation_srp',
    defaultValue: { int: false, prod: false },
  },
  PRICEDROP_NOTIFICATION: {
    key: 'toggle_pricedrop_notification',
    defaultValue: { int: false, prod: false },
  },
  ONE_TIME_SAVED_SEARCH: {
    key: 'toggle_one_time_saved_search',
    defaultValue: { int: true, prod: true },
  },
  REMOVE_ADSENSE_MWEB: {
    key: 'toggle_remove_adsense_mweb',
    defaultValue: { int: false, prod: false },
    trackingKey: 'kjca6246',
  },
  SEARCH_NEARBY_LOCATION: {
    key: 'toggle_search_nearby_location',
    defaultValue: { int: false, prod: false },
  },
  SRP_QUICK_FILTERS: {
    key: 'toggle_srp_quick_filters',
    defaultValue: { int: false, prod: false },
  },
  SAVED_SEARCH_WITHIN_FILTERS: {
    key: 'toggle_saved_search_within_filters',
    defaultValue: { int: false, prod: false },
  },
  SRP_CONTROLS_MWEB_REDESIGN: {
    key: 'toggle_srp_controls_mweb_redesign',
    defaultValue: { int: false, prod: false },
  },
  SRP_THIN_CONTENT_LINKS: {
    key: 'toggle_srp_thin_content_links',
    defaultValue: { int: false, prod: false },
  },
  POPULAR_NEAR_YOU_HORIZONTAL: {
    key: 'toggle_popular_near_you_horizontal',
    defaultValue: { int: true, prod: false },
  },
  SEO_SEARCH_LANDING_PAGES: {
    key: 'toggle_seo_search_landing_pages',
    defaultValue: { int: false, prod: false },
  },
  SRP_ADS_REINVENTION_TEST_TOGGLE: {
    key: 'toggle_exp_srp_ads_reinvention',
    defaultValue: { int: false, prod: false },
  },
  SAVED_SEARCH_COPY: {
    key: 'toggle_saved_search_copy',
    defaultValue: { int: false, prod: false },
  },
  SRP_GRID_VIEW_TOGGLE: {
    key: 'toggle_srp_grid_view',
    defaultValue: { int: false, prod: false },
  },
  HOMEPAGE_POPULAR_LISTINGS: {
    key: 'toggle_homepage_popular_listings',
    defaultValue: { int: false, prod: false },
  },
  FINANCING_REDIRECT: {
    key: 'toggle_financing_redirect',
    defaultValue: { int: false, prod: false },
  },
} as const satisfies RemoteToggleType
